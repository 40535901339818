<template>
  <div>
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :tab-arr="tabArr"
      :thead="thead"
      :on-fetch="getList"
      @command="handleOperation"
    >
     <template #filter="{idList}">
      <check-filter  :export-data="{tab: filterForm.tab, export_ids: idList}" :filter-change="onFilter" />
   </template>
   <template #item_nickname="{row}">{{row.user.nickname || '--'}}</template>
   <template #item_title_name="{row}">{{row.user.title_name || '--'}}</template>
   <template #item_check_status_text="{row}">
    <ListStatusText
              :text="row.check_status_text"
              :type="
                row.check_status_text === '已通过'
                  ? 'success'
                  : row.check_status_text === '审核中'
                  ? 'warning'
                  : row.check_status_text === '不通过'
                  ? 'danger'
                  : 'info'
              "
        ></ListStatusText>
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from '@/base/layout/ListLayout'

import {
  getCheckList,
} from '@/modules/venue/api/check-list'
import CheckFilter from'@/modules/venue/components/check-list/Filter'
import ListStatusText from "@/base/components/List/ListStatusText";
export default {
  components: { ListLayout, CheckFilter,ListStatusText },
  data() {
    return {
      filterForm: {
        tab: 'checking',
        page:1,
        page_size:15
      },
      tabArr: [
        {
          name: 'checking',
          label: '待处理',
          badge: 1
        },
        {
          name: 'checked',
          label: '已通过'
        },
        {
          name: 'unchecked',
          label: '已拒绝'
        },
        {
          name: 'canceled',
          label: '已取消'
        }
      ],
      thead: [
        {
          type: 'selection',
          fixed: 'left',
        }, /* 用来导出时选数据 */
        // {prop: 'code', label: '审批编号', width: 140},
        {prop: 'name', label: '场地',  minWidth: 170},
        {prop: 'nickname', label: '申请人',  minWidth: 140, type: 'slot'},
        {prop: 'title_name', label: '会内职务',  minWidth: 180 ,type: 'slot'},
        {prop: 'create_time', sortable: true, label: '提交时间',  minWidth: 200,},
        {prop: 'organization_name', label: '所属架构',  minWidth:120},
        {prop: 'check_status_text', label: '审批状态',  minWidth: 120,type: 'slot'},

        /*
        * 已通过：查看（进去后在底部显示审批记录按钮）

        已拒绝：查看（进去后底部显示通过，可以重新通过）

        已撤销：没有操作
                * */
        { type: 'operation', operations: [
            {command: 'check', text: '审批', visible: (r, q) => q.tab === 'checking'},
            {command: 'view', text: '查看', visible: (r, q) => ['checked', 'unchecked', 'canceled'].includes(q.tab)},
            // {command: 'approval_records', text: '审批记录', visible: (r, q) => q.tab === 'records'}
          ], visible: (r, q) => q.tab !== 'cancel',
          label: '操作',  minWidth: this.$route.name === 'ApprovalTmplRecord' ? 160 : 100
        },
      ],
    }
  },
  methods: {
    getList(q = {}) {
      return getCheckList({ ...this.filterForm, ...q })
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList()
    },
    handleOperation(e) {
      switch (e.command) {
        case 'check': {
          this.$router.push({
            name: 'VenueCheckDetail',
            params: {
              id: e.row.id,
            },
          })
          break
        }
        case 'view': {
          this.$router.push({
            name: 'VenueCheckView',
            params: {
              id: e.row.id,
            },
          })
          break
        }
        default:
      }
    },
    // 筛选回调
    onFilter(filterObj) {
      Object.assign(this.filterForm, filterObj, { page: 1 })
      this.$refs.listLayout.getList()
    },
  },
}
</script>

<style lang="scss" scoped></style>
