<!--
 * @Author: mulingyuer
 * @Date: 2021-04-09 17:49:59
 * @LastEditTime: 2021-09-09 10:09:35
 * @LastEditors: aleaner
 * @Description: 筛选
 * @FilePath: \vote\components\vote-list\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <layout-filter :onFilter="onFilter" :onReset="onReset" :collapseHeight="60" :toggleCollapseEnable="false" :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="70px" size="medium" class="filter-from">
      <div class="filter-item">
        <!-- <el-form-item label="审批编号：" prop="keyword">
            <el-input v-model="form.keyword" placeholder="请输入" clearable></el-input>
          </el-form-item> -->
        <el-form-item label="申请人：" prop="user">
          <el-input v-model="form.user" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="场地名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入" clearable></el-input>
        </el-form-item>

        <el-form-item label="所属架构：" prop="organization_id">

          <el-cascader v-model="form.organization_id" :options="organizationsList" :props="{
            emitPath: false,
            label: 'name',
            value: 'id',
            children: 'children',
            checkStrictly: true
            // multiple: true,
          }" filterable clearable></el-cascader>

        </el-form-item>
        <!-- <el-form-item label="审批状态：" prop="check_status">
          <el-select v-model="form.check_status">
            <el-option v-for="c in statusList" :key="c.id" :label="c.name" :value="c.id" />
          </el-select>
        </el-form-item> -->
      </div>
      <!-- <div class="filter-item">
      </div> -->
      <!-- <div class="filter-item">
          <DatePeriodPicker label="创建时间：" start-placeholder="开始日期" :start-time.sync="form.create_start_time"
            start-prop="create_start_time" end-placeholder="结束日期" :end-time.sync="form.create_end_time" end-prop="create_end_time" />
        </div> -->
    </el-form>
  </layout-filter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { allCategory } from "@/modules/approval/api/category-list";
import { organizationsList } from '@/modules/venue/api/check-list'
import {exportVenueReserve} from '../../api/check-list'
export default {
  name: 'check-filter',
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        "page": 1,
        "page_size": 10,
        "order_by": "create_time",
        "is_desc": 1,
        "name": '',
        "user": "", // 名称关键字
        organization_id: '',
        "check_status": -1, // -1:全部，0:禁用，1:启用
      },
      rules: {},
      categories: [],
      statusList: [
        {
          id: -1,
          name: "全部"
        },
        {
          id: 0,
          name: "待审批"
        },
        {
          id: 1,
          name: "已通过"
        },
        {
          id: 2,
          name: "不通过"
        }
      ],
      organizationsList: [],
    };
  },
  created() {
    allCategory().then(res => {
      this.categories = res.data
    }).catch(() => {
    })
    this.getOrganizationsList()
  },
  methods: {
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //重置
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onFilter();
    },
    //导出
    onExport() {
      const {...others} = {...this.exportData, ...this.form}
      return exportVenueReserve(others)
    },
    getOrganizationsList() {
      organizationsList().then(res => {
        this.organizationsList = res.data
      })
    }
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
<style lang="scss" scoped></style>
  