var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('list-layout',{ref:"listLayout",attrs:{"filter-form":_vm.filterForm,"tab-arr":_vm.tabArr,"thead":_vm.thead,"on-fetch":_vm.getList},on:{"command":_vm.handleOperation},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var idList = ref.idList;
return [_c('check-filter',{attrs:{"export-data":{tab: _vm.filterForm.tab, export_ids: idList},"filter-change":_vm.onFilter}})]}},{key:"item_nickname",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.user.nickname || '--'))]}},{key:"item_title_name",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.user.title_name || '--'))]}},{key:"item_check_status_text",fn:function(ref){
var row = ref.row;
return [_c('ListStatusText',{attrs:{"text":row.check_status_text,"type":row.check_status_text === '已通过'
                ? 'success'
                : row.check_status_text === '审核中'
                ? 'warning'
                : row.check_status_text === '不通过'
                ? 'danger'
                : 'info'}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }